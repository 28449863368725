<template lang="pug">
  .content.clearfix(v-if='article && renter && branch')
    script(v-html="jsonld" type="application/ld+json")
    agv-breadcrumbs(:items="breadcrumbItems" :show-arrow="false")
    .page-heading
      h1.page-title.page-title_top
        | {{articleNameWithSubstitutions | capitalize}}
    .article-container
      .photos-container
        agv-photo-selector(v-model="activePhoto" :photos="photos")
      .active-photo-wrapper
        div(v-for="photo in photos" :key="photo.id" v-show="activePhoto === photo")
          div(v-if="photo.is_infringing")
            i.el-icon-picture
            |
            | Фотография удалена
          div(v-else)
            .like-container(style="display:flex; justify-content:flex-end;")
              agv-article-like(:article="article" @update="updateArticle($event)" :is-logged-in="isLoggedIn")
            img(:alt="article.name" :src="photo.url" v-img="{group: 'photos', changed: onGalleryActivePhotoChanged, cursor: 'zoom-in'}")
            .active-photo-warehouse-comment(v-if="photo.description")
              span(v-html="photo.description")
      .tools-container
        agv-order-article(@range-changed="onRangeChanged"
          :article="article"
          :show-magnet="showMagnet"
          :show-discount="showDiscount"
          :renter="renter"
          :available="available"
          :order="order"
          :show-datepicker="false"
          :is-date-disabled="isDateDisabled")
        agv-key-features(:article="article")
        el-button.btn-add-to-comparison(v-if="isArticleInComparison(article.id, renter.id)" type="text" @click="removeArticleFromComparison(article.id, renter.id)")
          icon-remove-from-comparison.icon-add-to-comparison
          | Удалить из сравнения
        el-button.btn-add-to-comparison(v-else type="text" @click="addArticleToComparison(article.id, renter.id)")
          icon-add-to-comparison.icon-add-to-comparison
          | Добавить в сравнение
      agv-renter-info(:renter="renter" :branch="branch" :town-id="town_id")
    .additional-section(v-if='complementaryKinds')
      .title-additional
        | Дополняющие изделия
      .catalog
        agv-kind-list(:kinds="complementaryKinds")
    .additional-section(v-if='additionalArticles && additionalArticles.length > 0')
      .title-additional
        | Еще {{(articleTag ? articleTag.name_with_kind : kind.name) | lowercase}}
      .catalog(style='margin-left: -6px; margin-right: -6px;')
        agv-article(v-for="article in additionalArticles"
          :key="article.id + ':' + article.renter_id"
          :article="article"
          :show-discount="showDiscount"
          :town-id="article.town_id"
          is-in-wide-view
          :renter="getRenter(article.renter_id)"
          :show-rating="true"
          :show-like-button="true")
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

import NamedArticleSizes from 'Shared/NamedArticleSizes'

import AgvDatepicker from 'Aggregator/components/agv-datepicker'
import AgvKindList from 'Aggregator/components/agv-kind-list'

import AgvArticle from 'Aggregator/components/agv-article'
import AgvPhotoSelector from 'Aggregator/components/agv-photo-selector'

import toAggregatorArticles from 'Aggregator/shared/toAggregatorArticles'
import AgvBreadcrumbs from 'Aggregator/components/agv-breadcrumbs'
import AgvKeyFeatures from 'Aggregator/components/agv-key-features'
import AgvOrderArticle from 'Aggregator/components/agv-order-article'
import AgvRenterInfo from 'Aggregator/components/agv-renter-info'
import AgvRating from 'Aggregator/components/agv-rating'
import AgvArticleLike from 'Aggregator/components/agv-article-like'

import IconAddToComparison from 'Aggregator/sprites/icon-add-to-comparison.svg'
import IconRemoveFromComparison from 'Aggregator/sprites/icon-remove-from-comparison.svg'
import {renderMetaForComponent} from 'Aggregator/meta'
import {isDateDisabled} from 'Shared/order'
import {getSingleArticleAvailabilityUrl} from 'Aggregator/shared/availability'
import {getNameWithSubstitutions} from "Shared/articleNames";

let categoriesById = _.keyBy(window.categories, 'id')
let kindsById = _.keyBy(window.kinds, 'id')

const jsonLdFrom = (article, townId) => {
  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": article.name,
    "image": `https://pifakit.ru${article.photos[0].image.large.url}`,
    "description": article.key_feature_description,
    "offers": {
      "@type": "Offer",
      "url": `https://pifakit.ru/${townId}/renter/${article.renter_id}/article/${article.id}`,
      "priceCurrency": "RUB",
      "price": article.price,
      "availability": "https://schema.org/InStock"
    }
  }
}

export default {
  name: 'page-agv-article',
  props: {
    renter_id: String,
    article_id: String,
    town_id: String,
    town: Object
  },
  data() {
    return {
      availableForPeriod: null,
      isDataLoaded: false,
      article: null,
      renter: null,
      branch: null,
      additionalArticles: null,
      activePhoto: null,
      acquisition: null,
      returning: null,
      tagsByKindId: this.$store.state.tagsByKindId,
      complementaryKindIds: null,
      kinds: this.$store.state.kinds,
      jsonld: null
    }
  },
  methods: {
    updateArticle(article) {
      this.$set(this.article, 'is_liked', article.is_liked)
      this.$store.dispatch('customer/load')
    },
    getRenter(renterId) {
      return this.$store.state.rentersById[renterId]
    },
    onRangeChanged(range) {
      if (!range) {
        return
      }
      axios.get(
          getSingleArticleAvailabilityUrl(range[0], range[1], this.article_id, this.renter_id, this.town_id)
      ).then(({data: availableForPeriod}) => {
        this.availableForPeriod = availableForPeriod
      })
    },
    isDateDisabled,
    isArticleInComparison(articleId, renterId) {
      return _.includes(
          _.get(this.$store.state.comparedArticleIdsByRenter, renterId),
          articleId
      )
    },
    addArticleToComparison(articleId, renterId) {
      this.$store.commit('addArticleToComparison', {articleId, renterId})
    },
    removeArticleFromComparison(articleId, renterId) {
      this.$store.commit('removeArticleFromComparison', {articleId, renterId})
    },
    orderBy: _.orderBy,
    filterAvailableKinds(kinds) {
      if (!_.get(this.availableKindIds, 'length')) {
        return []
      }
      return _.filter(kinds, k => _.includes(this.availableKindIds, k.id))
    },
    onGalleryActivePhotoChanged(index) {
      this.activePhoto = this.photos[index]
    },
    fetchData() {
      this.isDataLoaded = false
      axios.get(
          `/api/renters/${this.renter_id}/articles/${this.article_id}`,
          {params: {town_id: this.town_id}}
      )
          .then(({data: {article, renter, branch, total_available, complementary_kind_ids}}) => {
            Object.assign(article, new NamedArticleSizes(article).value)

            article.gallery_photos = article.gallery_photos_for_marketplace

            this.article = article
            this.article.total_available = total_available
            this.article.renter_id = parseInt(this.renter_id)
            this.article.town_id = parseInt(this.town_id)
            this.renter = renter

            this.jsonld = jsonLdFrom(article, this.town_id)

            const storedRenter = this.$store.state.rentersById[renter.id]

            this.renter.rating_count = storedRenter.rating_count
            this.renter.avg_rating = storedRenter.avg_rating

            this.branch = branch
            this.complementaryKindIds = complementary_kind_ids

            this.isDataLoaded = true
            this.$emit('loaded')

            this.$store.dispatch('availability/loadAvailable', [this.article])

            this.activePhoto = this.photos[0]

            let query = this.articleTag ? `tag_ids_${this.articleTag.aggregator_tag_type_id}=${this.articleTag.id}` : ''

            axios.get(`/api/articles/${this.town_id}/by_kind_id/${this.article.aggregator_kind_id}?${query}`)
                .then(({data: {articles}}) => {
                  this.additionalArticles = _.take(
                      _.reject(articles, a => a.id === this.article.id && a.renter_id === this.article.renter_id),
                      8
                  )
                  toAggregatorArticles(this.additionalArticles, this.town_id)
                })
          })
    }
  },
  metaInfo() {
    return {
      titleTemplate: `%s | Пифакит`,
      title: this.metaTitle,
      meta: [{vmid: 'description', name: 'description', content: this.metaDescription}]
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['customer/isLoggedIn']
    },

    articleNameWithSubstitutions() {
      const nameSubstitutions = this.$store.getters.getArticleNameSubstitutions(this.article.renter_id)
      return getNameWithSubstitutions(this.article.name, nameSubstitutions)
    },
    showDiscount() {
      return !!_.get(this.$store.state.customer.current, 'is_professional')
    },
    showMagnet() {
      const isCustomerLoggedIn = !!_.get(this.$store.state.customer.current, 'id')
      return !isCustomerLoggedIn
    },
    order() {
      return this.$store.getters['order/getByRenterId'](this.renter_id)
    },
    metaState() {
      return {
        town: this.town,
        article: this.article
      }
    },
    metaTitle() {
      const nameSubstitutions = this.$store.getters.getArticleNameSubstitutions(this.renter_id)
      return getNameWithSubstitutions(
          renderMetaForComponent(this, 'title'),
          nameSubstitutions
      )
    },
    metaDescription() {
      return renderMetaForComponent(this, 'description')
    },
    availableKindIds() {
      return this.$store.state.availableKindIdsByTownId[this.town_id]
    },
    available() {
      return this.availableForPeriod || this.article.total_available
    },
    breadcrumbItems() {
      return _.compact(
          [
            this.$route.query.from_renter_page ? {
              name: this.renter.short_commercial_name,
              url: `/${this.town_id}/renter/${this.renter_id}`
            } : null
            ,
            {
              name: this.kind.name,
              url: `/${this.town_id}/${this.$route.query.from_renter_page ? `renter/${this.renter_id}/` : ''}category/${this.category.id}/${this.kind.id}`
            }
          ])
    },
    complementaryKinds() {
      if (!this.complementaryKindIds || this.complementaryKindIds.length === 0) {
        return null
      }

      let availableKinds = this.filterAvailableKinds(
          _.filter(this.kinds, k => _.includes(this.complementaryKindIds, k.id))
      )

      if (!availableKinds.length) {
        return null
      }

      const counts = this.$store.state.kindCountsByIdByTownId[this.town_id]

      return _.map(
          this.orderBy(availableKinds, ['compound_position', 'name']),
          k => {
            return {
              id: k.id,
              name: k.name,
              count: counts[k.id],
              url: `/${this.town_id}/category/${k.aggregator_category_id}/${k.id}`,
              image_url: k.image_url
            }
          }
      )
    },
    articleTag() {
      let tags = _.filter(
          this.tagsByKindId[this.article.aggregator_kind_id],
          m => _.includes(this.article.am_ids, m.id)
      )

      if (!tags || tags.length === 0) {
        return null
      }

      return _.orderBy(
          tags,
          'compound_position'
      )[0]
    },
    articlePhotos() {
      return _.map(
          _.take(
              this.article.photos.concat(this.article.gallery_photos),
              7
          ),
          (p) => {
            return {
              id: p.id,
              url: `${p.image.large.url}`,
              thumbnailUrl: `${p.image.thumbnail.url}`,
              description: null,
              type: 'article',
              is_infringing: p.is_infringing
            }
          }
      )
    },
    componentPhotos() {
      return _.map(
          this.article.components,
          (a) => {
            return {
              id: a.id,
              url: `${a.first_photo.large.url}`,
              thumbnailUrl: `${a.first_photo.thumbnail.url}`,
              description: null,
              type: 'product',
              is_infringing: a.first_photo.is_infringing
            }
          }
      )
    },
    warehousePhoto() {
      if (this.renter.warehouse_photo && this.renter.is_warehouse_photo_accepted) {
        return {
          id: null,
          url: this.renter.warehouse_photo.url,
          thumbnailUrl: this.renter.warehouse_photo.preview.url,
          description: (this.renter.warehouse_photo_description ?
                  this.renter.warehouse_photo_description
                      .replace(/<[^>]*>/g, '')
                      .replace(/(((http|https)\:\/\/)?(vk.com\/\S+))/gm, '<a href="https://$4" target="_blank">$1</a>')
                      .replace(/\n+/g, '<br/>') :
                  null
          ),
          type: 'warehouse'
        }
      }
    },
    photos() {
      return _.compact(this.articlePhotos.concat(this.componentPhotos).concat([this.warehousePhoto]))
    },
    category() {
      return categoriesById[this.kind.aggregator_category_id]
    },
    kind() {
      return kindsById[this.article.aggregator_kind_id]
    }
  },
  watch: {
    'article_id': ['fetchData'],
  },
  components: {
    AgvRating,
    AgvRenterInfo,
    AgvOrderArticle,
    AgvKeyFeatures,
    AgvBreadcrumbs,
    AgvDatepicker,
    AgvKindList,
    AgvArticle,
    AgvPhotoSelector,
    AgvArticleLike,
    IconAddToComparison,
    IconRemoveFromComparison
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
}

.content {
  font-size: 13px;
}

.article-container {
  display: flex;
}

.active-photo-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-grow: 1;
  padding-bottom: 40px;
  padding-right: 20px;
  padding-left: 40px;
}

.active-photo-wrapper img {
  max-height: 550px;
}

.photos-container {
  flex-basis: 160px;
  flex-shrink: 1;
  min-width: 100px;
  display: flex;
}

.tools-container {
  width: 249px;
  min-width: 249px;
  margin-left: 38px;
}

.title-additional {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.additional-section {
  margin-top: 40px;
}

.catalog {
  display: flex;
  flex-wrap: wrap;
}

.catalog .ag-article {
  flex-basis: 25%;
}

.content {
  margin-bottom: 60px;
}

.tools-container >>> .key-features-container {
  margin-top: 26px;
  padding: 0 31px;
}

/*[class$="container"] {*/
/*border: 1px solid red;*/
/*}*/

.icon-add-to-comparison {
  position: relative;
  top: 3px;
  margin-right: 7px;
}

.btn-add-to-comparison {
  margin-left: 30px;
  color: #0074d9;
  font-weight: normal;
}

.btn-add-to-comparison >>> path {
  stroke: #0074d9;
}

.active-photo-warehouse-comment {
  margin-top: 20px;
  color: grey;
  line-height: 150%;
}
</style>
