<template lang="pug">
  .agv-magnet
    .image-and-prices
      el-image(:src="`${article.preview_url}`" fit="scale-down")
      .prices
        span.article-name
          | {{article.name}}
        span.normal-price
          | {{article.price_in_town}} ₽
        span.discounted-price
          | {{article.price_with_discount}} ₽
    .copy-and-form
      .magnet-headline
        | Войдите, чтобы видеть
        br
        | цены для профи:
      .form
        el-input(placeholder="ваша@почта.ru" v-model="email"
          @keyup.enter.native="onSubmit" :class="{filled: !!email}")
        el-button(type="primary" @click="onSubmit" :disabled="isSubmitDisabled")
          | Войти
</template>

<script>
  import axios from 'axios'

  import Vue from 'vue'

  import eventBus from 'Shared/eventBus'
  import {reachGoal} from 'Shared/lib/metrika'
  import {EMAIL_REGEX} from 'Aggregator/shared/validation'

  export default {
    name: "agv-magnet",
    data() {
      return {
        email: this.$store.state.savedLogin
      }
    },
    computed: {
      isSubmitDisabled() {
        if (!this.email) {
          return true
        }
        return !this.email.match(EMAIL_REGEX)
      }
    },
    methods: {
      onSubmit() {
        if (this.isSubmitDisabled) {
          return
        }

        axios.get(`/api/aggregator_customer/can_login_with?login=${this.email}`)
          .then(
            ({data: result}) => {
              if (result) {
                reachGoal('sendLoginEmail:begin')
                axios.post(
                  `/api/aggregator_customer/login`, {login: this.email}
                ).then(() => {
                  Vue.$localStorage.set('login_back_url', this.$route.path)
                  eventBus.$emit('openLoginLinkSentModal', this.email)
                  reachGoal('sendLoginEmail:done')
                })
              } else {
                this.$store.commit('setSavedLogin', this.email)
                eventBus.$emit('proceedToLogin')
              }
            }
          )
      }
    },
    props: {
      article: {
        required: true,
        type: Object
      }
    }
  }
</script>

<style scoped>
  .agv-magnet {
    width: 100%;
    height: 295px;
    margin-top: 10px;
    margin-bottom: 30px;
    border: 1px solid #66a1ff;
    position: relative;
    grid-column: 1 / -1;
  }

  .image-and-prices {
    display: flex;
    top: 30px;
    left: 30px;
    position: absolute;
    width: 45%;
  }

  .el-image {
    max-width: 220px;
    min-width: 68%;
    height: 234px;
  }

  .prices {
    z-index: 999;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 128px;
  }

  .article-name {
    text-align: right;
    margin-bottom: 10px;
    margin-top: 8px;
    line-height: 1.4em;
    color: #4d4d4d;
    font-size: 14px;
  }

  .normal-price {
    font-size: 24px;
    color: #777;
    display: block;
    font-weight: bold;
    position: relative;
    text-align: right;
  }

  .normal-price::after {
    position: absolute;
    content: '';
    width: 50px;
    height: 13px;
    top: 7px;
    left: 12px;
    transform: scale(1.4) rotate(-4deg) translate(-1px, 1px);
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjMiIGhlaWdodD0iMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMDU3IDguNDY3YTQ3MC42ODkgNDcwLjY4OSAwIDAxMjkuNTA1LTUuMDg1QTQ5OC43MTkgNDk4LjcxOSAwIDAxNjAuOTQuMDUzYTEgMSAwIDAxMS4wNjkuODU4bC4yNzYgMS45NjhhMSAxIDAgMDEtLjkyIDEuMTM3Yy04LjgyOS42MjUtMTguMzcgMS42NTgtMjguNjI1IDMuMDk5YTUzNi43MjggNTM2LjcyOCAwIDAwLTMxLjAwNSA1LjI5NCAxIDEgMCAwMS0xLjE4Ny0uODQybC0uMjgtMS45ODFhMSAxIDAgMDEuNzg5LTEuMTE5eiIgZmlsbD0iI2Y0NCIgZmlsbC1ydWxlPSJldmVub2RkIiBvcGFjaXR5PSIuOCIvPjwvc3ZnPg==");
  }

  .discounted-price {
    font-size: 24px;
    display: block;
    color: hsla(107, 100%, 35%, 1);
    margin-left: -4px;
    font-weight: bold;
    text-align: right;
  }

  .copy-and-form {
    position: absolute;
    top: 30px;
    right: 35px;
    display: flex;
    flex-direction: column;
    width: 33%;
  }

  .magnet-headline {
    font-size: 20px;
    font-size: 1.6vw;
    color: #2967cc;
    width: 120%;
  }

  .form {
    margin-top: 48px;
  }

  .form .el-button {
    margin-top: 20px;
    width: 100%;
    height: 60px;
    font-size: 20px;
  }

  .form .el-input >>> input {
    border: 2px solid #27b300;
    height: 40px;
    font-size: 16px;
    color: #1e7f00;

    padding: 2px 10px;
  }

  .form .el-input >>> input::placeholder {
    color: #aed9a3;
  }

  .form .el-input >>> input:focus {
    border-width: 1px;
    padding: 3px 11px;
  }

  .form .el-input.filled >>> input {
    border-width: 1px;
    padding: 3px 11px;
  }
</style>
